import { Body, URLS } from '@cointracker/ui';
import {
  bannerContainer,
  bannerContent,
  bannerLink,
  bannerText,
} from './TaxDeadlineBanner.css';

interface TaxDeadlineBannerProps {
  text: string;
}

export const TaxDeadlineBanner = ({ text }: TaxDeadlineBannerProps) => (
  <section className={bannerContainer}>
    <div className={bannerContent}>
      <Body className={bannerText}>
        {text}
        <a className={bannerLink} href={URLS.UNIFIED_SUBSCRIPTION_PLANS_PAGE}>
          Get started now
        </a>
      </Body>
    </div>
  </section>
);
