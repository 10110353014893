import analytics, {
  trackLoggedOutGlobalSignUpButtonClicked,
} from '@/common/analytics';
import { Button, URLS, isURLActive, useGetPathname } from '@cointracker/ui';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import {
  getPartnerSlug,
  isAuthenticated,
  shouldShowAuthButtonsForPartner,
} from '../../utils';
import { DropdownNavigationMenu } from './DropdownNavigationMenu';
import {
  listDivider,
  listItemLink,
} from './DropdownNavigationMenu/DropdownNavigationMenu.css';
import {
  midButtonSize,
  navHref,
  navListAlignRight,
  navListCenter,
  navigationMenuLink,
  navigationMenuRoot,
  navigationMenuViewport,
  viewportPosition,
} from './NavigationList.css';

export const NavigationList = () => {
  const [shouldShowOpenAppButton, setShouldShowOpenAppButton] =
    useState<boolean>(false);
  const pathname = useGetPathname();
  const isActive = (url: string | string[]) => isURLActive(pathname, url);

  const partnerSlug = getPartnerSlug(pathname);
  const shouldShowAuthButtons =
    !partnerSlug || shouldShowAuthButtonsForPartner(partnerSlug);

  useEffect(() => {
    setShouldShowOpenAppButton(isAuthenticated());
  }, []);

  return (
    <>
      <NavigationMenu.Root className={navigationMenuRoot}>
        <NavigationMenu.List className={navListCenter}>
          <NavigationMenu.Item>
            <NavigationMenu.Link
              className={navigationMenuLink({
                active: isActive(URLS.INTEGRATIONS_PAGE),
              })}
              href={URLS.INTEGRATIONS_PAGE}
            >
              Integrations
            </NavigationMenu.Link>
          </NavigationMenu.Item>
          <NavigationMenu.Item>
            <NavigationMenu.Link
              className={navigationMenuLink({
                active: isActive(URLS.UNIFIED_SUBSCRIPTION_PLANS_PAGE),
              })}
              href={URLS.UNIFIED_SUBSCRIPTION_PLANS_PAGE}
            >
              Pricing
            </NavigationMenu.Link>
          </NavigationMenu.Item>

          <NavigationMenu.Item>
            <DropdownNavigationMenu title="Resources">
              <NavigationMenu.Link
                className={classNames(
                  navigationMenuLink({
                    active: isActive(URLS.PRICES_PAGE),
                  }),
                  listItemLink,
                )}
                href={URLS.PRICES_PAGE}
              >
                Crypto prices
              </NavigationMenu.Link>
              <div className={listDivider} />
              <NavigationMenu.Link
                className={classNames(navigationMenuLink(), listItemLink)}
                href={`${URLS.BLOG}/crypto-tax-guide`}
              >
                Tax guide
              </NavigationMenu.Link>
              <NavigationMenu.Link
                className={classNames(navigationMenuLink(), listItemLink)}
                href={URLS.BLOG}
              >
                Blog
              </NavigationMenu.Link>
              <NavigationMenu.Link
                className={classNames(navigationMenuLink(), listItemLink)}
                href={URLS.SUPPORT}
              >
                Support
              </NavigationMenu.Link>
              <NavigationMenu.Link
                className={classNames(navigationMenuLink(), listItemLink)}
                href={URLS.FEATURES}
              >
                Features
              </NavigationMenu.Link>
              <NavigationMenu.Link
                className={classNames(navigationMenuLink(), listItemLink)}
                href={URLS.CALCULATOR}
              >
                Trade Calculator
              </NavigationMenu.Link>
              <NavigationMenu.Link
                className={classNames(navigationMenuLink(), listItemLink)}
                href={URLS.PORTFOLIO_TRACKER}
              >
                Portfolio Tracker
              </NavigationMenu.Link>
            </DropdownNavigationMenu>
          </NavigationMenu.Item>

          <NavigationMenu.Item>
            <NavigationMenu.Link
              className={navigationMenuLink({
                active: isActive(URLS.TAX_PROS_LANDING_PAGE),
              })}
              href={URLS.TAX_PROS_LANDING_PAGE}
            >
              Tax Professionals
            </NavigationMenu.Link>
          </NavigationMenu.Item>
        </NavigationMenu.List>
        <div className={viewportPosition}>
          <NavigationMenu.Viewport className={navigationMenuViewport} />
        </div>
      </NavigationMenu.Root>
      <ul className={navListAlignRight}>
        {shouldShowOpenAppButton && (
          <a href={URLS.HOME_PAGE} className={navHref}>
            <Button
              variant="ghost"
              className={midButtonSize}
              onClick={() => analytics.track('Open app Button Clicked')}
            >
              Open app
            </Button>
          </a>
        )}
        {shouldShowAuthButtons && !shouldShowOpenAppButton && (
          <>
            <a href={URLS.AUTH_LOGIN} className={navHref}>
              <Button
                variant="ghost"
                className={midButtonSize}
                onClick={() =>
                  analytics.track('Sign In Button Clicked', {
                    button_text: 'Sign in',
                  })
                }
              >
                Sign in
              </Button>
            </a>
            <a href={URLS.GET_STARTED_PAGE} className={navHref}>
              <Button
                variant="primary"
                className={midButtonSize}
                onClick={() =>
                  trackLoggedOutGlobalSignUpButtonClicked(
                    'Try for free',
                    'top-navigation-bar',
                    'Top Navigation Bar',
                  )
                }
              >
                Try for free
              </Button>
            </a>
          </>
        )}
      </ul>
    </>
  );
};
