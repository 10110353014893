import { TaxDeadlineBanner } from '@/components/Header/TaxDeadlineBanner/TaxDeadlineBanner';
import { useGetPathname } from '@cointracker/ui';
import classNames from 'classnames';
import { header, sticky } from './Header.css';
import { NavBar } from './NavBar';
import { getIsIntegrationsPage } from './utils';

export const Header = () => {
  const pathname = useGetPathname();
  const isIntegrationsPage = getIsIntegrationsPage(pathname);

  const today = new Date();
  const shouldShowDeadlineBanner =
    today.getMonth() === 3 && today.getDate() < 16;

  const shouldShowExtensionBanner =
    today.getMonth() === 9 && today.getDate() < 16;

  return (
    <header className={classNames(header, !isIntegrationsPage && sticky)}>
      {shouldShowDeadlineBanner && (
        <TaxDeadlineBanner text="⏰ April 15 is the US Tax Deadline " />
      )}
      {shouldShowExtensionBanner && (
        <TaxDeadlineBanner text="🇺🇸 Don’t miss the Oct. 15th extension deadline! Get your crypto taxes done with CoinTracker today. " />
      )}
      <NavBar isSticky={!isIntegrationsPage} />
    </header>
  );
};
